import { HexVersionAtomicOperation } from "@hex/common";
import { createContext, useContext, useEffect, useMemo } from "react";

import {
  AtomicOperationController,
  ScopedAtomicOperationController,
} from "../atomic-operations";

import { Keys, evtModKey } from "./Keys";

const throwUninitialized = (): never => {
  throw new Error("AO context must be initialized!");
};

const dummyAtomicOperationController: AtomicOperationController<HexVersionAtomicOperation> =
  {
    dispatchAO: throwUninitialized,
    undoAO: throwUninitialized,
    redoAO: throwUninitialized,
    subscribeToStatus: throwUninitialized,
    getCurrentStatus: throwUninitialized,
    destroy: throwUninitialized,
    processQueuedOperations: throwUninitialized,
    getStats: throwUninitialized,
  };

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
const HexVersionAOContextInternal = createContext<
  AtomicOperationController<HexVersionAtomicOperation>
>(dummyAtomicOperationController);
HexVersionAOContextInternal.displayName = "HexVersionAOContext";

export const HexVersionAOContext = HexVersionAOContextInternal.Provider;

export function useScopedAO(): AtomicOperationController<HexVersionAtomicOperation> {
  const parentController = useHexVersionAOContext();
  return useMemo(
    () => new ScopedAtomicOperationController(parentController),
    [parentController],
  );
}

export function useHexVersionAOContext(): AtomicOperationController<HexVersionAtomicOperation> {
  return useContext(HexVersionAOContextInternal);
}

export function useGlobalUndoRedoForAOController(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controller?: AtomicOperationController<any>,
): void {
  useEffect(() => {
    if (controller == null) {
      return;
    }
    const globalUndo = (evt: KeyboardEvent): void => {
      if (evt.key === Keys.Z && evtModKey(evt) && evt.shiftKey) {
        controller.redoAO();
      } else if (evt.key === Keys.Z && evtModKey(evt) && !evt.shiftKey) {
        controller.undoAO();
      }
    };

    document.addEventListener("keydown", globalUndo);
    return () => {
      document.removeEventListener("keydown", globalUndo);
    };
  }, [controller]);
}

export function useGlobalUndoRedoHexVersionAO(): void {
  const controller = useHexVersionAOContext();
  useGlobalUndoRedoForAOController(controller);
}
