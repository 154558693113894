import {
  CUSTOM_ERROR_MIMETYPE,
  DISPLAY_TABLE_MIMETYPE,
  DisplayTableOutput,
  DistinctColumnValues,
  FILLED_DYNAMIC_VALUE_MIMETYPE,
  FilledDynamicValue,
  HEX_PRIVATE_PREFIX,
  MAP_FILLED_DYNAMIC_VALUE_MIMETYPE,
  OutputId,
  OutputType,
  SQL_COMPILED_QUERY_MIMETYPE,
  SQL_STATUS_MIMETYPE,
  SqlCompiledQuery,
  SqlStatus,
  notEmpty,
} from "@hex/common";
import JSON5 from "json5";
import { useMemo } from "react";
import { Success } from "runtypes";

import { getTableOutputs } from "../components/cell/renderers/query-cells-shared/utils.js";
import { getMostRecentMetadataOutputContent } from "../components/output/metadataOutputs";
import { UseCellDataResult } from "../components/output/useCellData";
import { OutputMP } from "../redux/slices/appSessionMPSlice";

import { logErrorMsg, logInfoMsg } from "./logging";
import { useHexFlag } from "./useHexFlags.js";

export function getCompiledSqlOutput(
  outputs: UseCellDataResult[],
): SqlCompiledQuery[] {
  return outputs
    .filter((o) =>
      o.outputFragment.frontendOutputContents.some(
        (oc) => oc.mimeType === SQL_COMPILED_QUERY_MIMETYPE,
      ),
    )
    .map((o) => {
      const compiledQueryOutput = o.outputFragment.frontendOutputContents.find(
        (oc) => oc.mimeType === SQL_COMPILED_QUERY_MIMETYPE,
      );
      if (compiledQueryOutput == null) {
        return null;
      }

      try {
        return SqlCompiledQuery.check(
          JSON.parse(JSON.parse(compiledQueryOutput.contents)),
        );
      } catch (e) {
        logErrorMsg(e, "Error parsing SQL Compiled Query contents");
        return null;
      }
    })
    .filter(notEmpty);
}

export function getDynamicValuesFromOutputs(
  outputs: UseCellDataResult[],
): Record<string, FilledDynamicValue | undefined> {
  return outputs
    .flatMap((o) => o.outputFragment.frontendOutputContents)
    .filter(
      (o) =>
        o.mimeType === FILLED_DYNAMIC_VALUE_MIMETYPE ||
        o.mimeType === MAP_FILLED_DYNAMIC_VALUE_MIMETYPE,
    )
    .map((o) => {
      let value;
      try {
        const contentsLength = o.contents.length;
        const innerJson = JSON.parse(o.contents);
        // We use JSON5 below to properly handle NaN (which is allowed in python json.dumps),
        // but which is not technically valid JSON.
        // Unfortunately, JSON5 is extremely slow for some datasets, so we first try to parse
        // using the much faster native JSON.parse.
        let outerData: unknown;
        try {
          outerData = JSON.parse(innerJson);
        } catch (e: unknown) {
          // Likely due to an NaN in the data, fallback to JSON5 lib
          if (e instanceof SyntaxError) {
            if (contentsLength > 500_000) {
              // Log instances of parsing DFs with NaNs over 500KB so we can see how common this issue is,
              // if it happens often we'll want to look at a faster parsing method here
              logInfoMsg("Possible slow JSON5 parsing of data", {
                safe: {
                  outputContentId: o.id,
                  contentsLength,
                },
              });
            }

            outerData = JSON5.parse(innerJson);
          } else {
            throw e;
          }
        }
        value = FilledDynamicValue.validate(outerData);
      } catch (e) {
        // gracefully handle errors so we don't crash the page
        console.error(e);
        value = { success: false };
      }
      return value;
    })
    .filter((r): r is Success<FilledDynamicValue> => r.success)
    .reduce<Record<string, FilledDynamicValue>>((acc, curr) => {
      acc[curr.value.variableName] = curr.value;
      return acc;
    }, {});
}

export const getTraceback = (
  outputs: { outputFragment: OutputMP }[],
): { traceback: string; outputId: string } | undefined => {
  for (const output of outputs) {
    const { frontendOutputContents: contents, outputType: type } =
      output.outputFragment;
    if (type == null || type !== OutputType.ERROR) {
      continue;
    }
    const tracebackOutput = contents.find(
      (content) =>
        content.mimeType === "traceback" ||
        content.mimeType === CUSTOM_ERROR_MIMETYPE,
    );
    if (tracebackOutput != null) {
      let traceback = JSON.parse(tracebackOutput.contents);
      if (tracebackOutput.mimeType === CUSTOM_ERROR_MIMETYPE) {
        traceback = JSON.parse(traceback).traceback;
      }
      return {
        traceback,
        outputId: output.outputFragment.id,
      };
    }
  }
};

export const getTableContents = (
  richOutputs: UseCellDataResult[],
): {
  finished: boolean;
  loadedRows: number;
  loadedBytes: null;
  numColumns: number;
  outputId: OutputId;
} | null => {
  const stringContents = richOutputs.find(
    (o) =>
      o.outputFragment.frontendOutputContents.find(
        (c) => c.mimeType === DISPLAY_TABLE_MIMETYPE,
      ) != null,
  )?.outputFragment.frontendOutputContents[0]?.contents;

  if (stringContents != null) {
    const parsedTableOutput = JSON.parse(JSON.parse(stringContents));
    if (DisplayTableOutput.guard(parsedTableOutput)) {
      return {
        finished: true,
        loadedRows: parsedTableOutput.rowCount,
        loadedBytes: null,
        numColumns: parsedTableOutput.columns.length,
        outputId: richOutputs[0].outputFragment.id,
      };
    }
  }

  return null;
};

export type SqlStatusWithOutputId = SqlStatus & { outputId: OutputId };
export const useSqlStatus = (
  metadataOutputs: UseCellDataResult[],
  outputs?: UseCellDataResult[],
): SqlStatusWithOutputId | null => {
  const unidfQueryModeEnabled = useHexFlag("unidf-query-mode");
  return useMemo(() => {
    const mostRecentSqlStatus = getMostRecentMetadataOutputContent(
      metadataOutputs.map(({ outputFragment }) => outputFragment),
      SQL_STATUS_MIMETYPE,
    );
    try {
      if (mostRecentSqlStatus) {
        return {
          ...SqlStatus.check(
            JSON.parse(JSON.parse(mostRecentSqlStatus.contents)),
          ),
          outputId: mostRecentSqlStatus.outputId,
        };
      }

      // When in unidf query mode, the SQL status is not output, but we can
      // still try to get the parts of the query result metadata such as the
      // row count from the table output
      if (unidfQueryModeEnabled && outputs) {
        const { richOutputs } = getTableOutputs(outputs);
        return getTableContents(richOutputs);
      }
    } catch (e) {
      logErrorMsg(e, "Error parsing SQL Status contents");
    }
    return null;
  }, [metadataOutputs, outputs, unidfQueryModeEnabled]);
};

export const useDistinctColumnValues = (
  outputs: UseCellDataResult[],
): DistinctColumnValues | null => {
  return useMemo(() => {
    const dynamicValue =
      getDynamicValuesFromOutputs(outputs)[
        `${HEX_PRIVATE_PREFIX}distinct_column_values`
      ];
    if (dynamicValue && DistinctColumnValues.guard(dynamicValue.value)) {
      return dynamicValue.value;
    } else {
      return null;
    }
  }, [outputs]);
};
