export const join = (str: string | string[], delimiter?: string): string => {
  if (Array.isArray(str)) {
    return str.join(delimiter || "");
  }
  return str;
};

export const split = (str: string): string | string[] => {
  const parts = str
    .split("\n")
    .map((part, i, arr) => (i === arr.length - 1 ? part : `${part}\n`));
  if (parts.length > 1) {
    return parts;
  }
  return str;
};

export const maybePluralize = (
  length: number,
  singular: string,
  plural: string,
): string => {
  return length === 1 ? singular : plural;
};
