const removeKeys = (arr: string[], excludeKeys?: string[]): string[] => {
  if (excludeKeys == null) {
    return arr;
  }

  return arr.filter((key) => excludeKeys?.indexOf(key) === -1);
};

/**
 * Returns true if the objects are shallowly equal (all attribtes are strictly equal to each other)
 * with the option to skip some
 *
 * @param object1
 * @param object2
 * @param excludeKeys
 */
export const shallowCompare = (
  object1: any, // eslint-disable-line
  object2: any, // eslint-disable-line
  excludeKeys?: string[],
  debug?: boolean,
): boolean => {
  if (object1 == null || object2 == null) {
    return object1 === object2;
  }

  const object1Keys = removeKeys(Object.keys(object1), excludeKeys);
  const object2Keys = removeKeys(Object.keys(object2), excludeKeys);

  if (object1Keys.length !== object2Keys.length) {
    if (debug) {
      // eslint-disable-next-line no-console
      console.log(
        "objects did not have the same keys",
        object1Keys,
        object2Keys,
      );
    }
    return false;
  }

  for (const key of object1Keys) {
    if (object1[key] !== object2[key]) {
      if (debug) {
        // eslint-disable-next-line no-console
        console.log(`Key ${key} was not equal`, object1[key], object2[key]);
      }
      return false;
    }
  }

  return true;
};
