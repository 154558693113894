import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";

import { HexSpinner, HexSpinnerSizes } from "../hex-components/HexSpinner";

const StyledSpinner = styled(HexSpinner)<{ $autoHeight: boolean }>`
  width: 100%;
  ${({ $autoHeight }) => ($autoHeight ? "height: auto;" : "height: 100%;")}
`;

export interface LoadingViewProps {
  $autoHeight?: boolean;
  $small?: boolean;
  className?: string;
}

/**
 * This component exits to make transitioning between
 * React suspense states a bit smoother in the happy
 * case async work is resolved quickly.
 *
 * This is effectively just a workaround the fact
 * React concurrent mode is experimental
 */
export const LoadingView: React.ComponentType<LoadingViewProps> = memo(
  function LoadingView({ $autoHeight = false, $small = false, className }) {
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
      const timeout = setTimeout(() => {
        setShowSpinner(true);
      }, 400);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    if (!showSpinner) {
      return null;
    }

    return (
      <StyledSpinner
        $autoHeight={$autoHeight}
        className={className}
        size={$small ? HexSpinnerSizes.STANDARD : HexSpinnerSizes.LARGE}
      />
    );
  },
);
