import { AppSessionAtomicOperation } from "@hex/common";
import { createContext, useContext } from "react";

import { AtomicOperationController } from "../atomic-operations";

const throwUninitialized = (): never => {
  throw new Error("AO context must be initialized!");
};

const dummyAtomicOperationController: AtomicOperationController<AppSessionAtomicOperation> =
  {
    dispatchAO: throwUninitialized,
    undoAO: throwUninitialized,
    redoAO: throwUninitialized,
    subscribeToStatus: throwUninitialized,
    getCurrentStatus: throwUninitialized,
    destroy: throwUninitialized,
    processQueuedOperations: throwUninitialized,
    getStats: throwUninitialized,
  };

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
const AppSessionAOContextInternal = createContext<
  AtomicOperationController<AppSessionAtomicOperation>
>(dummyAtomicOperationController);
AppSessionAOContextInternal.displayName = "AppSessionAOContext";

export const AppSessionAOContext = AppSessionAOContextInternal.Provider;

export function useAppSessionAOContext(): AtomicOperationController<AppSessionAtomicOperation> {
  return useContext(AppSessionAOContextInternal);
}
