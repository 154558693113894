import { isValidRedirectUrl } from "@hex/common";
import React, { ComponentProps, useEffect } from "react";
import { Redirect } from "react-router";

import { getUrlOrg } from "../orgs";

export interface OrgAwareRedirectProps
  extends Omit<ComponentProps<typeof Redirect>, "to"> {
  /** This field must be a full url with orgId and starting slash, e.g. /myorg/hex/... */
  toUrl: string;
}

export const OrgAwareRedirect: React.ComponentType<OrgAwareRedirectProps> =
  React.memo(function OrgAwareRedirect(props) {
    const currentOrg = getUrlOrg();
    const [, orgId, ...rest] = props.toUrl.split("/");

    // If the orgId doesn't match, we have to use window.location.href since we use basename for all other routes
    useEffect(() => {
      if (
        orgId !== currentOrg &&
        isValidRedirectUrl(props.toUrl, window.location.hostname)
      ) {
        window.location.href = props.toUrl;
      }
    }, [currentOrg, orgId, props.toUrl]);

    // Otherwise strip the org and pass the updated version
    return <Redirect {...props} to={`/${rest.join("/")}`} />;
  });
