import { Dispatch, SetStateAction, useEffect, useState } from "react";

const useForceUpdate = (): [Date, Dispatch<SetStateAction<Date>>] => {
  return useState(new Date());
};

export const useRefreshComponent = (
  updateIntervalMs: number,
  refreshOnce = false,
): void => {
  const [_, forceUpdate] = useForceUpdate();
  return useEffect(() => {
    if (updateIntervalMs > 0 && refreshOnce) {
      const timeout = setTimeout(
        () => forceUpdate(new Date()),
        updateIntervalMs,
      );

      return () => {
        clearTimeout(timeout);
      };
    } else if (updateIntervalMs > 0) {
      const interval = setInterval(
        () => forceUpdate(new Date()),
        updateIntervalMs,
      );

      return () => {
        clearInterval(interval);
      };
    }
  }, [forceUpdate, updateIntervalMs, refreshOnce]);
};
