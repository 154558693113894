import { getProductionEnvironment } from "@hex/common";
import { useMemo } from "react";

import faviconDevelop from "../static/public/favicon-develop.svg";
import faviconProdEditing from "../static/public/favicon-editing.svg";
import faviconLocal from "../static/public/favicon-local.svg";
import faviconStaging from "../static/public/favicon-staging.svg";
import faviconProd from "../static/public/favicon.svg";

export const useGetFavicon = ({ inProject = false }): string => {
  const environment = getProductionEnvironment(window.location.hostname);

  return useMemo(() => {
    switch (environment) {
      case "local":
        return faviconLocal;
      case "develop":
        return faviconDevelop;
      case "staging":
        return faviconStaging;
      case "prod":
      case "single-tenant":
        return inProject ? faviconProdEditing : faviconProd;
      default:
        return faviconProd;
    }
  }, [environment, inProject]);
};
