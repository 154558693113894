import {
  CellId,
  CellType,
  ChartLayer,
  DateTimeString,
  MagicEventId,
  NoCodeCellDataframe,
  ParameterInputType,
  SortableCell,
  UserId,
  flattenSortedCells,
  guardNever,
  notEmpty,
  sortCells,
} from "@hex/common";
import { useMemo } from "react";

import { useMicroMemoize } from "../hooks/useMicroMemoize";
import { useSelector } from "../redux/hooks.js";
import {
  ChildCellLocation,
  RelativeCellLocation,
} from "../redux/slices/cell-location/CellLocation.js";
import { normalizeCellLocation } from "../redux/slices/cell-location/resolveCellLocation.js";
import { CellContentsMP, CellMP } from "../redux/slices/hexVersionMPSlice";
import {
  ActionBarMode,
  selectActionBarState,
} from "../redux/slices/logicViewSlice.js";

import { inputNameToLabel } from "./inputLabel";
import { useProjectContext } from "./projectContext.js";

export type InlineCellStub = {
  __typename: "Cell";
  id: CellId;
  cellContents: InlineCellContentsStub;
  order: string;
  latestMagicEventId: MagicEventId | null;
};

type InlineCellContentsStub =
  | { readonly __typename: "CodeCell" }
  | {
      readonly __typename: "DisplayTableCell";
    }
  | {
      readonly __typename: "MarkdownCell";
    }
  | {
      readonly __typename: "TextCell";
    }
  | { readonly __typename: "MetricCell" }
  | {
      readonly __typename: "Parameter";
      readonly inputCellId: string;
      readonly inputType: ParameterInputType;
    }
  | { readonly __typename: "SqlCell" }
  | {
      readonly __typename: "VegaChartCell";
    }
  | {
      readonly __typename: "MapCell";
    }
  | { readonly __typename: "WritebackCell" }
  | { readonly __typename: "DbtMetricCell" }
  | { readonly __typename: "PivotCell" }
  | { readonly __typename: "FilterCell" }
  | { readonly __typename: "ComponentImportCell" }
  | { readonly __typename: "CollapsibleCell" }
  | { readonly __typename: "ChartCell" }
  | { readonly __typename: "BlockCell" }
  | { readonly __typename: "ExploreCell" };

export type WithIndex<T extends InlineCellStub> = T & { cellIndex: number };

type CellBlockContentsCommon<T extends InlineCellStub> = {
  cells: WithIndex<T>[];
  key: string;
};

export type AirlockBlockContents<T extends InlineCellStub> =
  CellBlockContentsCommon<T> & {
    type: "AIRLOCK";
    userId: UserId | null;
    isDraft: boolean;
  };

type InlineBlockContents<T extends InlineCellStub> =
  CellBlockContentsCommon<T> & {
    type: "INLINE";
  };

type DefaultBlockContents<T extends InlineCellStub> =
  CellBlockContentsCommon<T> & {
    type: "DEFAULT";
  };

export type CellBlockContents<T extends InlineCellStub> =
  | AirlockBlockContents<T>
  | InlineBlockContents<T>
  | DefaultBlockContents<T>;

export const isInlineCell = (cellContents: InlineCellContentsStub): boolean => {
  return (
    (cellContents.__typename === "Parameter" &&
      cellContents.inputType !== ParameterInputType.TABLE) ||
    cellContents.__typename === "MetricCell"
  );
};

export const isInlineCellByType = (
  cellType: CellType,
  isTableInput?: boolean,
): boolean => {
  return (
    (cellType === CellType.INPUT && !isTableInput) ||
    cellType === CellType.METRIC
  );
};

/**
 * Sorts a list of cells and removes deleted cells at a nested level
 *
 * @deprecated
 *
 * @see selectFlattenedSorted for performantly getting a sorted cell list
 * @see sortCells for general sorting
 * @see flattenSortedCells if you just need an array
 */
export const getSortedCells = <
  T extends SortableCell & { deletedDate: DateTimeString | null },
>(
  cells: readonly T[],
  options?: {
    reverse?: boolean;
  },
): T[] => {
  const result = flattenSortedCells(
    sortCells(cells.filter((c) => c.deletedDate == null)),
  );

  if (options?.reverse) {
    return result.toReversed();
  } else {
    return result;
  }
};

export const getCellBlocks = <T extends InlineCellStub>({
  actionBarLocation,
  cellWithIndexMaker = (cell, cellIndex) => ({ ...cell, cellIndex }),
  parentCellId,
  sortedCells,
}: {
  sortedCells: T[];
  cellWithIndexMaker?: (cell: T, cellIndex: number) => WithIndex<T>;
  /** When location is null, we treat as flagged off. */
  actionBarLocation: RelativeCellLocation | ChildCellLocation | null;
  parentCellId: CellId | null;
}): CellBlockContents<T>[] => {
  // Intentional comparison with undefined.
  const cellBlocks: CellBlockContents<T>[] = [];
  let currentBlock: CellBlockContents<T>;

  const airlockBlock: AirlockBlockContents<T> = {
    type: "AIRLOCK",
    cells: [],
    key: `action-bar-${actionBarLocation?.position}-${actionBarLocation?.type === "child" ? actionBarLocation.parentCellId : actionBarLocation?.targetCellId}`,
    isDraft: true,
    userId: null,
  };

  if (
    actionBarLocation?.type === "child" &&
    actionBarLocation.parentCellId === parentCellId &&
    actionBarLocation.position === "first"
  ) {
    currentBlock = airlockBlock;
    cellBlocks.push(currentBlock);
  }

  sortedCells.forEach((cell, i) => {
    const isInlineCell_ = isInlineCell(cell.cellContents);

    const cellWithIndex = cellWithIndexMaker(cell, i);

    // Potentially allocate an empty airlock BEFORE target cell.
    if (
      actionBarLocation?.type === "relative" &&
      actionBarLocation.targetCellId === cell.id &&
      actionBarLocation.position === "before"
    ) {
      currentBlock = airlockBlock;
      cellBlocks.push(currentBlock);
    }

    const shouldAppendCell = currentBlock?.type === "INLINE" && isInlineCell_;

    if (currentBlock && shouldAppendCell) {
      currentBlock.cells.push(cellWithIndex);
    } else if (isInlineCell_) {
      currentBlock = {
        type: "INLINE",
        cells: [cellWithIndex],
        key: cell.id,
      };
      cellBlocks.push(currentBlock);
    } else {
      currentBlock = {
        type: "DEFAULT",
        cells: [cellWithIndex],
        key: cell.id,
      };
      cellBlocks.push(currentBlock);
    }

    // Potentially allocate an empty airlock AFTER target cell.
    if (
      actionBarLocation?.type === "relative" &&
      !(i === sortedCells.length - 1 && parentCellId == null) &&
      actionBarLocation.targetCellId === cell.id &&
      actionBarLocation.position === "after"
    ) {
      currentBlock = airlockBlock;
      cellBlocks.push(currentBlock);
    }
  });

  if (
    actionBarLocation?.type === "child" &&
    parentCellId != null &&
    actionBarLocation.parentCellId === parentCellId &&
    actionBarLocation.position === "last"
  ) {
    currentBlock = airlockBlock;
    cellBlocks.push(currentBlock);
  }

  return cellBlocks;
};

export const useGetCellBlocks = <T extends InlineCellStub>({
  isMagicCellCreationEnabled = false,
  parentCellId,
  showAddCellBar = false,
  sortedCells,
}: {
  sortedCells: T[];
  isMagicCellCreationEnabled?: boolean;
  parentCellId: CellId | null;
  /**
   * We only want to show the add cell bar in the logic view context.
   */
  showAddCellBar?: boolean;
}): CellBlockContents<T>[] => {
  const { hexVersionId } = useProjectContext();

  const makeCellWithIndex = useMicroMemoize(
    (cell: T, cellIndex: number) => ({
      ...cell,
      cellIndex,
    }),
    { maxSize: 200 },
  );

  const actionBarLocation = useSelector((state) => {
    const actionBarState = selectActionBarState(state);

    const showActionBar =
      actionBarState.isOpen &&
      (isMagicCellCreationEnabled ||
        (showAddCellBar && actionBarState.mode === ActionBarMode.ADD_CELL));

    if (!showActionBar) {
      return null;
    }

    return normalizeCellLocation({
      hexVersionId,
      state,
      location: actionBarState.location,
    });
  });

  return useMemo(
    () =>
      getCellBlocks({
        parentCellId,
        sortedCells,
        cellWithIndexMaker: makeCellWithIndex,
        actionBarLocation: actionBarLocation,
      }),
    [parentCellId, sortedCells, makeCellWithIndex, actionBarLocation],
  );
};

export const getDataframeName = (
  df?: NoCodeCellDataframe,
): string | undefined => {
  if (df == null) {
    return;
  } else if (typeof df === "string") {
    return df;
  } else if (df && "dataframeName" in df) {
    return df.dataframeName;
  }
};

export const getColumnReferences = (layer: ChartLayer): string[] => {
  const columnNames: (string | null)[] = [];
  columnNames.push(layer.xAxis.dataFrameColumn ?? null);
  layer.series.forEach((series) => {
    columnNames.push(...series.dataFrameColumns.map((dc) => dc));
  });
  return columnNames.filter(notEmpty);
};

/**
 * Gets the input parameters for a cell.
 * @param contents the Cell contents
 * @returns a list of search strings from the inputs of these cell types
 */
export const getCellInputParams = (contents?: CellContentsMP): string[] => {
  if (!contents?.__typename) {
    return [];
  }
  const params: string[] = [];

  switch (contents.__typename) {
    case "ChartCell": {
      const chartSpecType = contents.chartSpec.type;
      switch (chartSpecType) {
        case "concat": {
          const concatSpec = contents.chartSpec.charts;
          concatSpec?.forEach((chart) => {
            const layers = chart.layers;
            layers?.forEach((layer) => {
              const dfName = getDataframeName(layer.dataFrame);
              if (dfName != null) {
                params.push(dfName);
              }

              params.push(...getColumnReferences(layer));
            });
          });
          break;
        }
        case "layered":
          contents.chartSpec.layers?.forEach((layer) => {
            const dfName = getDataframeName(layer.dataFrame);
            if (dfName != null) {
              params.push(dfName);
            }

            params.push(...getColumnReferences(layer));
          });
          break;
        case "unsupported":
          break;
        default:
          guardNever(chartSpecType, chartSpecType);
      }
      break;
    }
    case "VegaChartCell": {
      if (contents.metadata) {
        contents.metadata.byLayer?.forEach((layer) => {
          if (layer.selectedDataFrameVariableName) {
            params.push(layer.selectedDataFrameVariableName);
          }
        });
      }
      break;
    }
    case "ExploreCell":
      // If the exploreDataframe is a string, then it is the dataframe name.
      if (typeof contents.exploreDataframe === "string") {
        params.push(contents.exploreDataframe);
      } else if (
        contents.exploreDataframe &&
        "dataframeName" in contents.exploreDataframe
      ) {
        params.push(contents.exploreDataframe.dataframeName);
      }
      break;
    case "DisplayTableCell":
    case "PivotCell": {
      if (contents.dataframe) {
        const dfName = getDataframeName(contents.dataframe);
        if (dfName != null) {
          params.push(dfName);
        }
      }
      // Add in the column properties (headers) as searchable fields
      if (contents.displayTableConfig.columnProperties) {
        params.push(
          ...contents.displayTableConfig.columnProperties.map(
            (col) => col.originalName,
          ),
        );
      }
      break;
    }
    case "MapCell": {
      contents.map.layers?.forEach((layer) => {
        const layerType = layer.type;
        switch (layerType) {
          case "dataset": {
            const dfName = layer.data.datasetName;
            if (dfName != null) {
              params.push(dfName);
            }
            break;
          }
          case "area":
          case "text":
          case "heatmap":
          case "scatter": {
            const dfName = layer.data.dataFrameName;
            if (dfName != null) {
              params.push(dfName);
            }
            break;
          }
          default: {
            guardNever(layerType, layerType);
          }
        }
      });
      break;
    }
    case "MetricCell": {
      if (contents.valueVariableName) {
        params.push(contents.valueVariableName);
      }
      break;
    }
    case "FilterCell": {
      if (contents.dataframe) {
        const dfName = getDataframeName(contents.dataframe);
        if (dfName != null) {
          params.push(dfName);
        }
      }
      break;
    }
    case "WritebackCell": {
      if (contents.dataframeName) {
        params.push(contents.dataframeName);
      }
      break;
    }
    // All text based cells will contian the input reference as text in the cell.
    case "CodeCell":
    case "TextCell":
    case "MarkdownCell":
    case "SqlCell":
      break;
    // Any cell type here indicates that they have not been implemented. This is helpful for us
    // to catch and make sure we can continue to add any new cell types with inputs here in the future.
    case "ComponentImportCell":
    case "CollapsibleCell":
    case "Parameter":
    case "DbtMetricCell":
    case "BlockCell":
      break;
    default:
      guardNever(contents, contents);
  }

  // Dedupe input variables (only included once in the UI)
  return [...new Set(params)];
};

export const getCellOutputParams = (contents?: CellContentsMP): string[] => {
  const params: string[] = [];

  if (contents?.__typename === "Parameter" && contents.name) {
    params.push(contents.name);
  }
  if (contents && "resultVariable" in contents) {
    const hideOutput =
      "outputResult" in contents && contents.outputResult === false;
    if (contents.resultVariable && !hideOutput) {
      params.push(contents.resultVariable);
    }
  }
  if (contents && "cellReferencesV2" in contents) {
    contents.cellReferencesV2?.newParams?.forEach((newParam) => {
      if (newParam.param) {
        params.push(newParam.param);
      }
    });
  }
  return params;
};

type ResolveCellLabelArgs = {
  cellIndex: number;
} & (
  | {
      cell: CellMP;
      cellContents: CellContentsMP;
    }
  | {
      cell: null;
      cellContents: null;
    }
);

export const resolveCellLabel = ({
  cellIndex,
  ...args
}: ResolveCellLabelArgs): string => {
  const cellIndexHuman = cellIndex + 1;

  if (args.cell == null) {
    return `Cell ${cellIndexHuman}`;
  }
  const { cell, cellContents } = args;

  if (cell.label != null) {
    return cell.label;
  }

  switch (cellContents.__typename) {
    case "Parameter":
      return inputNameToLabel(cellContents.name);
    case "MetricCell":
      return `Value ${cellIndexHuman}`;
    case "DisplayTableCell":
      return `Table ${cellIndexHuman}`;
    case "SqlCell":
      return `SQL ${cellIndexHuman}`;
    case "MarkdownCell":
      return `Markdown ${cellIndexHuman}`;
    case "TextCell":
      return `Text ${cellIndexHuman}`;
    case "CodeCell":
      return `Code ${cellIndexHuman}`;
    case "VegaChartCell":
    case "ChartCell":
      return `Chart ${cellIndexHuman}`;
    case "MapCell":
      return `Map ${cellIndexHuman}`;
    case "WritebackCell":
      return `Writeback ${cellIndexHuman}`;
    case "DbtMetricCell":
      return `Metric ${cellIndexHuman}`;
    case "PivotCell":
      return `Pivot ${cellIndexHuman}`;
    case "FilterCell":
      return `Filter ${cellIndexHuman}`;
    case "ComponentImportCell":
      return (
        cellContents.componentVersionStub?.title ??
        `Component ${cellIndexHuman}`
      );
    case "CollapsibleCell":
      return `Section ${cellIndexHuman}`;
    case "BlockCell":
      return `Block ${cellIndexHuman}`;
    case "ExploreCell":
      return `Explore ${cellIndexHuman}`;
    default:
      guardNever(
        cellContents,
        (cellContents as { __typename: string })?.__typename,
      );
      return `Cell ${cellIndexHuman}`;
  }
};
