import {
  AppSessionAtomicOperation,
  AppSessionCellId,
  CREATE_APP_SESSION_CELL,
  CellId,
  CellRunMode,
  RUN_CELL,
  uuid,
} from "@hex/common";

import { AtomicOperationController } from "../atomic-operations/AtomicOperationController";

export interface RunCellArgs {
  dispatchAppSessionAO: AtomicOperationController<AppSessionAtomicOperation>["dispatchAO"];
  appSessionCellId: AppSessionCellId | undefined;
  cellId: CellId;
  cellRunMode: CellRunMode | undefined;
  forceOverwriteCache?: boolean;
  executeTrace?: boolean;
  source?: string;
  forLogicView: boolean | undefined;
  maybeSkipSql?: boolean;
  isPendingMagicDiffRun?: boolean;
}

/**
 * If calling from react, prefer `useRunCell` since it will
 * auto use the most up-to-date source depending on cell type.
 */
export const runCell = async ({
  appSessionCellId,
  cellId,
  cellRunMode,
  dispatchAppSessionAO,
  executeTrace,
  forLogicView,
  forceOverwriteCache,
  isPendingMagicDiffRun,
  maybeSkipSql,
  source,
}: RunCellArgs): Promise<void> => {
  let maybeNewAppSessionCellId = appSessionCellId;
  // Normally we don't want to want to create app session cells in the frontend
  // but if this is a legacy appsession that is missing appsessioncells we should create it
  // Alternatively, if the mp model is just out of sync, then this may fail, but we should handle insyncness generally in the controller
  // The last case that could happen is if you run a cell immediately after creating the cell, but before the cell's side-effect to create the appsessioncell is still in flight
  // In this case, this will error as well, but running the button again should fix it. That race condition is also extremely hard to hit in practice
  if (!maybeNewAppSessionCellId) {
    maybeNewAppSessionCellId = uuid() as AppSessionCellId;
    await dispatchAppSessionAO(
      CREATE_APP_SESSION_CELL.create({
        appSessionCellId: maybeNewAppSessionCellId,
        cellId,
      }),
    ).promise;
  }
  await dispatchAppSessionAO(
    RUN_CELL.create({
      appSessionCellId: maybeNewAppSessionCellId,
      cellRunMode,
      forceOverwriteCache,
      executeTrace,
      source,
      forLogicView,
      maybeSkipSql,
      isPendingMagicDiffRun,
    }),
  ).promise;
};
