export function inputNameToLabel(name: string): string {
  if (name === "") {
    return name;
  }

  const replaceUnderscores = name.replace(/_/g, " ");
  return (
    replaceUnderscores.charAt(0).toLocaleUpperCase() +
    replaceUnderscores.substring(1)
  );
}
