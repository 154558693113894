// eslint-disable-next-line no-restricted-imports
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

import { analyticsEnabled } from "./data";

// All logs need to contain adequate info to debug to reduce noise and provide visbility into where users are hitting problems in the app.

/**
 * Sets the user context for Datadog
 */
export function setUserForLogging(userInfo: {
  username: string | undefined;
  id: string;
  email: string;
}): void {
  const ddUserInfo = {
    id: userInfo.id,
    email: userInfo.email,
    name: userInfo.username,
  };
  datadogLogs.setUser(ddUserInfo);
  datadogRum.setUser(ddUserInfo);
}

const FAILED_TO_PARSE = "Unable to parse exception for logging";

interface LoggingData {
  /** Information to log that doesn't contain sensitive user-generated values */
  safe?: { [key: string]: unknown };
}

/**
 * Captures an error and records in Datadog.
 *
 * @param error The error being captured, will be stringified unless of Error type
 * @param contextMessage context for the thrown error to ease debugging. This can be as simple as the component / calling method name of the code.
 * @param data Extra parameters to log
 */
export function logErrorMsg(
  error: unknown,
  contextMessage: string,
  data: LoggingData = {},
): void {
  try {
    let exception;
    if (error instanceof Error) {
      exception = error;
    } else {
      exception = new Error(JSON.stringify(error));
    }
    if (analyticsEnabled) {
      datadogLogs.logger.error(contextMessage, data.safe, exception);
    } else {
      console.error(error, contextMessage, data.safe);
    }
  } catch (e) {
    if (analyticsEnabled) {
      datadogLogs.logger.error(FAILED_TO_PARSE, data.safe);
    } else {
      console.error(error, contextMessage, FAILED_TO_PARSE, e);
    }
  }
}

export function logInfoMsg(message: string, data: LoggingData = {}): void {
  if (analyticsEnabled) {
    datadogLogs.logger.info(message, data.safe);
  } else {
    // eslint-disable-next-line no-console
    console.info(message, data.safe);
  }
}

export function logWarnMsg(message: string, data: LoggingData = {}): void {
  if (analyticsEnabled) {
    datadogLogs.logger.warn(message, data.safe);
  } else {
    console.warn(message, data.safe);
  }
}

export function logDuration(name: string, durationMs: number): void {
  if (analyticsEnabled) {
    datadogRum.addAction(`Duration Tracked`, {
      durationName: name,
      durationMs,
    });
  }
}
