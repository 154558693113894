/* eslint-disable no-console */
interface TraceLogEntry {
  traceId: string;
  status: "STARTING" | "OK" | "ERROR";
  name: string;
}

function formatTraceEntry(entry: TraceLogEntry): string {
  return `status: ${entry.status.padEnd(8)}, name: ${entry.name}, trace id: ${
    entry.traceId
  }`;
}

/**
 * Circular buffer of recent trace ids to surface in the UI
 */
class TraceLog {
  public numberError = 0;
  public numberInProgress = 0;

  private readonly maxErrorEntries: number;
  private readonly errorEntries: TraceLogEntry[] = [];

  private readonly maxInProgressEntries: number;
  private inProgressEntries: TraceLogEntry[] = [];

  private readonly maxCompletedEntries: number;
  private completedEntries: TraceLogEntry[] = [];

  private shouldLog = false;

  constructor({
    maxCompletedEntries,
    maxErrorEntries,
    maxInProgressEntries,
  }: {
    maxErrorEntries: number;
    maxInProgressEntries: number;
    maxCompletedEntries: number;
  }) {
    this.maxErrorEntries = maxErrorEntries;
    this.maxInProgressEntries = maxInProgressEntries;
    this.maxCompletedEntries = maxCompletedEntries;
  }

  public push(entry: TraceLogEntry): void {
    // we manually ignore these request types since they are pretty noisy
    // and _rarely_ the cause of any issue being debugged.
    if (
      entry.name === "AcquireLock" ||
      entry.name === "UpdatePresence" ||
      entry.name === "GetLocksForHexVersion"
    ) {
      return;
    }

    if (entry.status === "ERROR") {
      this.numberError++;

      this.errorEntries.push(entry);
      if (this.errorEntries.length > this.maxErrorEntries) {
        this.errorEntries.shift();
      }
    }

    if (entry.status === "STARTING") {
      this.numberInProgress++;

      this.inProgressEntries.push(entry);
      if (this.inProgressEntries.length > this.maxInProgressEntries) {
        this.inProgressEntries.shift();
      }
    } else {
      this.numberInProgress--;
      this.inProgressEntries = this.inProgressEntries.filter(
        (e) => e.traceId !== entry.traceId,
      );
    }

    if (entry.status === "OK") {
      this.completedEntries.push(entry);
      if (this.completedEntries.length > this.maxCompletedEntries) {
        this.completedEntries.shift();
      }
    }

    if (this.shouldLog) {
      console.log(formatTraceEntry(entry));
    }
  }

  public getErrors(): readonly TraceLogEntry[] {
    return this.errorEntries;
  }

  public getErrorsFormatted(): string {
    return this.getErrors()
      .map((entry) => formatTraceEntry(entry))
      .join("\n");
  }

  public printErrorsFormatted(): void {
    console.log(this.getErrorsFormatted());
  }

  public getInProgress(): readonly TraceLogEntry[] {
    return this.inProgressEntries;
  }

  public getInProgressFormatted(): string {
    return this.getInProgress()
      .map((entry) => formatTraceEntry(entry))
      .join("\n");
  }

  public printInProgressFormatted(): void {
    console.log(this.getInProgressFormatted());
  }

  public getCompleted(): readonly TraceLogEntry[] {
    return this.completedEntries;
  }

  public getCompletedFormatted(): string {
    return this.getCompleted()
      .map((entry) => formatTraceEntry(entry))
      .join("\n");
  }

  public printCompletedFormatted(): void {
    console.log(this.getCompletedFormatted());
  }

  public toggleLogging = (): void => {
    this.shouldLog = !this.shouldLog;
    console.log("logging enabled for trace log");
  };
}

export const TRACE_LOG = new TraceLog({
  maxErrorEntries: 25,
  maxInProgressEntries: 5,
  maxCompletedEntries: 10,
});
