import { retry } from "@hex/common";
import { ComponentType, LazyExoticComponent, lazy } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function reactLazyRetry<T extends ComponentType<any>>(
  moduleImport: () => Promise<{ default: T }>,
): LazyExoticComponent<T> {
  return lazy(() =>
    retry({ func: moduleImport, retryCount: 5, interval: 150 }),
  );
}
