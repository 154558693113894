import { logErrorMsg } from "./logging.js";

function isCrossOriginError(e: Error): boolean {
  return e instanceof DOMException && e.name === "SecurityError";
}

function errorContextMessage(prefix: string): string {
  return `${prefix}Storage`;
}
let resolvedLocalStorage = null;
try {
  // eslint-disable-next-line no-restricted-globals
  resolvedLocalStorage = localStorage;
} catch (err) {
  if (err instanceof Error && !isCrossOriginError(err)) {
    logErrorMsg(err, errorContextMessage("Local"));
  }
}

let resolvedSessionStorage = null;
try {
  // eslint-disable-next-line no-restricted-globals
  resolvedSessionStorage = sessionStorage;
} catch (err) {
  if (err instanceof Error && !isCrossOriginError(err)) {
    logErrorMsg(err, errorContextMessage("Session"));
  }
}

const BrowserStorages = {
  Local: resolvedLocalStorage,
  Session: resolvedSessionStorage,
};
export type BrowserStorageType = keyof typeof BrowserStorages;

function wrapBrowserStorageMethod<K extends keyof Storage>(
  storageType: BrowserStorageType,
  key: K,
): (...args: Parameters<Storage[K]>) => ReturnType<Storage[K]> | null {
  const storage = BrowserStorages[storageType];

  return (...args: Parameters<Storage[K]>) => {
    if (!storage) {
      return null;
    }
    try {
      return storage[key](...args);
    } catch (err) {
      if (err instanceof Error && !isCrossOriginError(err)) {
        logErrorMsg(err, errorContextMessage(storageType));
      }
    }
    return null;
  };
}

export const safeLocalStorage = {
  getItem: wrapBrowserStorageMethod("Local", "getItem"),
  setItem: wrapBrowserStorageMethod("Local", "setItem"),
  removeItem: wrapBrowserStorageMethod("Local", "removeItem"),
  enabled: BrowserStorages["Local"] != null,
};

export const safeSessionStorage = {
  getItem: wrapBrowserStorageMethod("Session", "getItem"),
  setItem: wrapBrowserStorageMethod("Session", "setItem"),
  removeItem: wrapBrowserStorageMethod("Session", "removeItem"),
  enabled: BrowserStorages["Session"] != null,
};

export const SafeBrowserStorages = {
  Local: safeLocalStorage,
  Session: safeSessionStorage,
};
