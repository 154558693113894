import { gql } from "@apollo/client";
import { OrgRole, isOrgRoleSuperset } from "@hex/common";

import { useFeatureGates } from "../components/feature-gate/FeatureGateContext";
import { useCurrentUser } from "../hooks/me/useCurrentUser.js";
import { ORG_ID } from "../orgs";

import { useGetOrgProjectCountQuery } from "./useEnforceProjectLimit.generated";

gql`
  query GetOrgProjectCount($orgId: OrgId!, $orgMember: Boolean!) {
    orgById(orgId: $orgId) @include(if: $orgMember) {
      id
      projectCount
    }
  }
`;

export const useEnforceProjectLimit = (): {
  projectLimitEnforced?: boolean;
  projectLimit?: number;
  projectCount?: number;
  loading: boolean;
} => {
  const currentUser = useCurrentUser();
  const orgMember = currentUser?.orgRole
    ? isOrgRoleSuperset(currentUser?.orgRole, OrgRole.MEMBER)
    : false;

  const { projectLimit } = useFeatureGates();
  const {
    data: projectCountData,
    error: projectCountError,
    loading: projectCountDataLoading,
  } = useGetOrgProjectCountQuery({
    variables: { orgId: ORG_ID, orgMember },
    skip: !projectLimit == null,
  });

  const projectCount = projectCountData?.orgById?.projectCount ?? null;

  if (projectCountDataLoading) {
    return {
      loading: true,
    };
  }
  if (
    projectCountError ||
    projectCountData == null ||
    projectLimit == null ||
    projectCount == null
  ) {
    return {
      projectLimitEnforced: false,
      loading: false,
    };
  }

  return {
    projectLimitEnforced: projectCount >= projectLimit,
    projectCount,
    projectLimit,
    loading: false,
  };
};
