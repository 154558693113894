import {
  AGENT_TYPE_HEADER,
  AGENT_VERSION_HEADER,
  ALLOW_ARBITRARY_QUERIES_HEADER,
  AUTHORIZATION_HEADER,
  AgentType,
  CSRF_SAFE_HEADER,
  CSRF_SAFE_HEADER_VALUE,
  ORG_ID_HEADER,
  OrgId,
  TRACE_ID_HEADER,
  createTraceId,
} from "@hex/common";

import { ORG_ID } from "../orgs";

import { userBearerToken, webClientVersion } from "./data";

export const createRequestHeaders = (orgId?: OrgId): Record<string, string> => {
  const headers: Record<string, string> = {
    [CSRF_SAFE_HEADER]: CSRF_SAFE_HEADER_VALUE,
    [ORG_ID_HEADER]: orgId ?? ORG_ID,
    [TRACE_ID_HEADER]: createTraceId(),
    [AGENT_TYPE_HEADER]: AgentType.WEB_CLIENT,
    [AGENT_VERSION_HEADER]: webClientVersion ?? "<unknown>",
  };

  if (process.env.NODE_ENV !== "production") {
    headers[ALLOW_ARBITRARY_QUERIES_HEADER] = "true";
  }

  if (userBearerToken) {
    headers[AUTHORIZATION_HEADER] = `Bearer ${userBearerToken}`;
  }

  return headers;
};
