import { gql } from "@apollo/client";
import React from "react";

import { useFetchBigQueryAccessTokenForSuccessView } from "./BigQueryOAuthSuccessView.generated.js";
import { OAuthSuccessView } from "./OAuthSuccessView.js";

gql`
  mutation FetchBigQueryAccessTokenForSuccessView(
    $code: String!
    $state: String!
  ) {
    fetchBigQueryAccessToken(code: $code, state: $state) {
      id
      accessTokenExpires
    }
  }
`;

export const BigQueryOAuthSuccessView: React.FunctionComponent = React.memo(
  function BigQueryOAuthSuccessView() {
    const [fetchBigQueryAccessToken] =
      useFetchBigQueryAccessTokenForSuccessView();

    return (
      <OAuthSuccessView
        broadcastChannel="bigquery-oauth"
        fetchAccessToken={fetchBigQueryAccessToken}
      />
    );
  },
);
