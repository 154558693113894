import { AppSessionId } from "@hex/common";
import { Provider, createContext, useContext } from "react";

// Right now all of these variables usually change in sync with each other, so its fine to keep in the same object for re-rendering purposes
// However, in the future we could add a selector here to reduce re-renders
/**
 * Session context contains some globally useful fields relevant to an AppSession that can be used in any rendering of an AppSession.
 * We use React Contexts for this instead of Redux, because we want to nest this context in some cases
 * (previewing a different appsession in the publish dialog).
 */
export interface SessionContext {
  appSessionId: AppSessionId;
  /** Whether or not the appSessionId is part of the URL */
  appSessionIdVisible: boolean;
  isLogicSession: boolean;
  sessionEditableOverride?: boolean;
}

const SessionContextInternal =
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  createContext<SessionContext | undefined>(undefined);
SessionContextInternal.displayName = "SessionContext";

// cast to non-nullable, so we don't inadvertenly pass undefined and cause crashes
export const SessionContextProvider =
  SessionContextInternal.Provider as Provider<SessionContext>;

type UseSessionContextResult<S> = S extends false
  ? SessionContext
  : SessionContext | undefined;

interface UseSessionContextArgs<S extends boolean> {
  /**
   * @default false
   */
  safe?: S;
}
export function useSessionContext<S extends boolean = false>(
  args: UseSessionContextArgs<S> = {},
): UseSessionContextResult<S> {
  const safe = args.safe ?? false;
  const context = useContext(SessionContextInternal);
  if (!context && !safe) {
    throw new Error("Session context has not been initialized!");
  }
  return context as UseSessionContextResult<S>;
}
