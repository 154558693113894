import { GoogleDriveOauthStatus } from "@hex/common";
import { BroadcastChannel } from "broadcast-channel";
import React, { useCallback, useEffect } from "react";

import { useQueryParams } from "../route/routes";

export const GOOGLE_DRIVE_OAUTH_CHANNEL = "google-drive-oauth";
export interface GoogleDriveOauthMessage {
  status: GoogleDriveOauthStatus;
  account?: string | null;
}

export const GoogleDriveOauthCompleteView: React.ComponentType = React.memo(
  function GoogleDriveOauthCompleteView() {
    const queryParams = useQueryParams();
    const finishAuth = useCallback(async () => {
      const isAuthorized = queryParams.get("authorized");
      let message: {
        status: GoogleDriveOauthStatus;
        account?: string | null;
      };
      if (isAuthorized === "false") {
        message = {
          status: queryParams.get("reason") as GoogleDriveOauthStatus,
          account: queryParams.get("reason"),
        };
      } else {
        message = { status: GoogleDriveOauthStatus.SUCCESS };
      }
      const googleDriveChannel: BroadcastChannel<GoogleDriveOauthMessage> =
        new BroadcastChannel(GOOGLE_DRIVE_OAUTH_CHANNEL);
      // this message signals the original component to close this window
      await googleDriveChannel.postMessage(message);
      await googleDriveChannel.close();
    }, [queryParams]);

    useEffect(() => {
      void finishAuth();
      // we only want this effect to run once so we keep deps empty
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
  },
);
