import scrollIntoView, { Options } from "scroll-into-view-if-needed";

export interface CustomScrollIntoViewOptions extends Options {
  offsetTop?: number;
  offsetLeft?: number;
}

export const customScrollIntoView = (
  ele: HTMLElement,
  options: CustomScrollIntoViewOptions,
  onFinished?: () => void,
): void => {
  const { behavior: initialBehavior, offsetLeft = 0, offsetTop = 0 } = options;

  const customBehavior: Options["behavior"] = (actions) => {
    // The below is stolen from scroll-into-view-if-needed
    const canSmoothScroll = "scrollBehavior" in document.body.style;

    actions.forEach(({ el, left, top }, idx) => {
      if (idx === 0) {
        const onScrollEnd = () => {
          el.removeEventListener("scrollend", onScrollEnd);
          onFinished?.();
        };
        el.addEventListener("scrollend", onScrollEnd);
      }
      // browser implements the new Element.prototype.scroll API that supports `behavior`
      // and guard window.scroll with supportsScrollBehavior
      if (el.scroll && canSmoothScroll) {
        el.scroll({
          top: top + offsetTop,
          left: left + offsetLeft,

          // initialBehavior shouldn't be a function here but we do this for type checking
          behavior:
            typeof initialBehavior !== "function" ? initialBehavior : "auto",
        });
      } else {
        el.scrollTop = top + offsetTop;
        el.scrollLeft = left + offsetLeft;
      }
    });
  };

  const behavior =
    typeof initialBehavior === "function" ? initialBehavior : customBehavior;

  scrollIntoView(ele, {
    ...options,
    behavior,
  });
};
